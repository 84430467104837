/* Legal Documents Container */
.legal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 80vh;
  background: #101010;
  border-bottom: 18px solid #111111;
  overflow: visible;
  pointer-events: all !important;
  transform: translateY(-120%);
  transition: transform 800ms cubic-bezier(0.385, 0.035, 0.515, 0.86);
  transition-delay: 100ms;
  z-index: 991100;
}

.legal-container-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Base styles for legal documents component */
.legal-docs-header {
  position: absolute;
  display: block;
  width: 100vw;
  height: 80px;
  top: -200px;
  text-align: center;
  padding: 8px;
  margin: 0;
  background: #111111;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
  transition: top 800ms cubic-bezier(0.385, 0.035, 0.515, 0.86);
  transition-delay: 500ms;
  z-index: 10;
}

.legal-docs-title {
  position: relative;
  display: block;
  width: 100vw;
  height: 80px;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  padding: 8px;
  margin: auto;
  margin-top: 10px;
  opacity: 0.4;
}

.legal-docs-close {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  color: var(--fs-text-color, rgba(255, 255, 255, 0.35));
  cursor: pointer;
  padding: 18px;
  font-size: 36px;
  line-height: 1;
  margin-right: 18px;
  margin-top: 4px;
  opacity: 0;
  transition:
    opacity 400ms ease-in-out 1200ms,
    color 400ms ease-in-out 0ms;
}

.legal-docs-close:hover {
  color: var(--fs-text-color, rgba(255, 255, 255, 1));
}

.legal-docs-top {
  position: fixed;
  bottom: 28px;
  right: 8px;
  width: 40px;
  height: 40px;
  background: url("chevron.svg") no-repeat center center;
  background-size: 100%;
  border: none;
  cursor: pointer;
  padding: 18px;
  margin-right: 18px;
  margin-top: 4px;
  opacity: 0.25;
  visibility: hidden;
  transition:
    opacity 300ms ease-in-out,
    visibility 300ms ease-in-out;
  z-index: 10;
}

.legal-docs-top.show {
  visibility: visible;
}

.legal-docs-top.show:hover {
  opacity: 1;
}

.legal-download {
  position: fixed;
  top: 82vh;
  left: 18px;
  width: 195px;
  height: 40px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  padding-left: 55px;
  opacity: 0.25;
  transition: opacity 300ms ease-in-out;
  z-index: 10;
}

.legal-download i {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 14px;
  top: -4px;
  background: url("download.svg") no-repeat center center;
  background-size: 100%;
  opacity: 0.75;
  pointer-events: none;
  border: none;
}

.legal-download:hover {
  opacity: 1;
}

.legal-docs-content {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 100px;
  padding: 100px;
  padding-top: 18px;
  padding-bottom: 280px;
  text-align: left;
  mask: linear-gradient(to top, transparent 3%, black 90%, transparent 100%);
  opacity: 0;
  transition: opacity 200ms ease-in;
  transition-delay: 0ms;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
}

.legal-docs-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  justify-content: center;
  align-items: center;
}

.legal-docs-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: var(--fs-primary-color, #4a90e2);
  animation: spin 1s linear infinite;
}

.legal-docs-document {
  position: relative;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.legal-docs-metadata {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.legal-docs-error {
  text-align: center;
  padding: 32px;
  color: #ff6b6b;
}

.legal-docs-tabs {
  position: absolute;
  width: 50%;
  max-width: 400px;
  min-width: 280px;
  bottom: -65px;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  background: #111111;
  border-radius: 4px;
}

.legal-docs-tab {
  background: transparent;
  color: var(--fs-text-color, rgba(255, 255, 255, 0.5));
  padding: 8px 24px;
  border: none;
  cursor: pointer;
}

.legal-docs-tab:hover {
  color: var(--fs-text-color, rgba(255, 255, 255, 0.8));
}

.legal-docs-tab--active {
  color: var(--fs-text-color, rgba(255, 255, 255, 1));
  font-weight: 600;
}

.legal-docs-document {
  font-family:
    system-ui,
    -apple-system,
    sans-serif;
  font-size: 1.55rem;
  line-height: 1.6;
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
}

.legal-docs-document h1 {
  font-size: 1.8em;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.legal-docs-document .last-updated {
  color: #666;
  margin-bottom: 2rem;
  font-style: italic;
}

.legal-docs-document h2 {
  font-size: 1.5em;
  margin: 2rem 0 1rem;
  font-weight: bold;
}

.legal-docs-document h3 {
  font-size: 1.15em;
  margin: 1.5rem 0 1rem;
  opacity: 0.65;
  font-weight: bold;
}

.legal-docs-document p {
  margin: 1rem 0;
}

.legal-docs-document ul,
.legal-docs-document ol {
  margin: 1rem 0;
  padding-left: 2rem;
  list-style-position: outside;
}

.legal-docs-document ul {
  list-style-type: disc;
  color: #666;
  opacity: 0.65;
}

.legal-docs-document ol {
  list-style-type: decimal;
}

.legal-docs-document li {
  margin: 0.5rem 0;
  padding-left: 0.5rem;
  list-style: disc;
}

body.fs-legal-show {
  overflow: hidden !important;
}

body.fs-legal-show .legal-container {
  transform: translateY(0);
  transition: transform 500ms ease-out;
  transition-delay: 100ms;
}

body.fs-legal-show .legal-docs-header {
  top: 0;
}

body.fs-legal-show .legal-docs-close {
  opacity: 1;
}

body.fs-legal-show .legal-docs-content {
  opacity: 1;
  transition: all 1200ms ease-in-out;
  transition-delay: 800ms;
}

/* Responsive Media Queries */
@media (max-width: 1280px) {
  .legal-docs-document {
    font-size: 1.25rem;
  }
}

@media (max-width: 920px) {
  .legal-docs-content {
    padding: 60px;
    padding-top: 15px;
  }
  .legal-docs-document {
    font-size: 1.15rem;
  }
}

@media (max-width: 768px) {
  .legal-docs-content {
    padding: 48px;
    padding-top: 15px;
  }
  .legal-docs-document {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .legal-docs-content {
    padding: 20px;
    padding-top: 10px;
  }
  .legal-docs-document {
    font-size: 0.92rem;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
